.message-container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #d9d9d9;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 8px;
  text-align: left;
  margin: 24px 5vw 24px 5vw;
}

.lincoln-message-container {
  font-family: "MillerWGL", "Times New Roman", Times, serif;
}

.message-display-container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.message-text {
  width: 100%;
  display: flex;
  text-align: justify;
}

.display-message-container {
  padding: 0 5vw 0 5vw;
}

.message-display-buttons {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0562d2;
  font-weight: 700;
  font-size: 1.4rem;
}
