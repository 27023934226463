@use "~ag-grid-community/styles" as ag;
@import "globalVariables";

.ag-theme-alpine {
  @include ag.grid-styles(
    (
      (
        // use theme parameters where possible
        alpine-active-color: darkblue,
        header-background-color: #efefef
      )
    )
  );

  .ag-row-odd {
    background-color: var(--fds-color--fourth);
  }

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-style: italic;
    background-image: linear-gradient(var(--fds-color--fourth), #ececec);
  }
}

.ford-motion-font {
  font-family: "Ford F-1", sans-serif;
}

.accept-reject-button {
  --shadow: none;
  min-width: 120px;
  &:hover,
  &:focus {
    --shadow: none !important;
  }
  display: block;
  margin: 6px auto;
}

table {
  width: 90%;
  margin: auto;
  border-spacing: 0;
}

.fmc-table th,
.fmc-table td {
  vertical-align: middle;
  width: max-content;
}

.fmc-table tr {
  //height: 5em;
}

.ReactModal__Overlay {
  z-index: 25;
}

.close-button {
  float: right;
}

.ReactModal__Content {
  width: 70%;
  margin: auto;
  height: fit-content;
}

@media (min-width: 768px) {
  .ReactModal__Content {
    width: 40%;
  }
}

@media (min-width: 480px) {
  .ReactModal__Content {
    width: 50%;
  }
}

.public-message-modal-container {
  display: flex;
  flex-direction: column;
}

.modal-header {
  text-align: center;
  padding-bottom: 10px;
  color: var(--fds-color--primary);
  font-weight: bold;
}

.national-modal-header {
  text-align: start;
  font-size: 2rem;
  margin: 0 10% 0 10%;
  color: var(--fds-color--tertiary);
  padding-bottom: 1.6rem;
}

.success-message-modal-header {
  text-align: start;
  margin: 0 10% 0 10%;
  color: #333333;
  font-weight: bold;
  font-size: 1.5rem;
  padding-top: 1rem;
}

.lincoln-modal-header {
  text-align: center;
  padding-bottom: 10px;
  font-size: 2.4rem;
  color: var(--fds-color--primary);
}

.lincoln-public-message-modal-description {
  text-align: center;
  color: var(--fds-color--error1);
}

.center-content {
  flex: 100%;
  display: flex;
  justify-content: center;
}

.justify-close-button-right {
  flex: 100%;
  display: flex;
  justify-content: end;
  margin-right: 5%;
}

.modal-description {
  margin: 0 10% 0 10%;
  padding-top: 24px;
  padding-bottom: 4px;
}

.modal-information {
  margin: 0 10% 0 10%;
  font-weight: bold;
}

.modal-text {
  margin: 0 10% 0 10%;
  border-radius: 1rem;
}

.modal-text-padding {
  padding: 1rem;
}

.public-message-success {
  text-align: center;
}

.modal-text::placeholder {
  opacity: 0.5;
}

.fmc-button {
  --shadow: 0;
  --radius: 1em;
  --height: 3rem;
}

.are-you-sure-modal-button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 1rem;
}

.overwritten-fds-outlined-button {
  display: flex;
  height: var(--button-primary-height-default, 40px);
  padding: var(--space-MD, 12px) var(--space-LG, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--space-None, 0px);
  border-radius: var(--button-primary-radius-default, 400px);
  border: var(--border-width-xs, 1px) solid
    var(--color-stroke-onlight-interactive, #0562d2);

  color: var(--color-text-onLight-interactive, #0562d2);
  text-align: center;

  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.overwritten-fds-primary-button {
  display: flex;
  height: var(--button-primary-height-default, 40px);
  padding: var(--space-MD, 12px) var(--space-LG, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--space-None, 0px);
  border-radius: var(--button-primary-radius-default, 400px);
  background: var(--button-primary-onlight-default, #066fef);
  color: var(--color-text-onDark-strong, #fff);
  text-align: center;

  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.modal-style {
  font-family: "Ford F-1", sans-serif;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 33%;
  max-width: 1000px;
  min-width: 500px;
  border-radius: 1.6rem;
}

.fmc-dialog__content {
  border: solid var(--fds-color--gray2) 1px;
  border-radius: 0.5em;
}

.lincoln-dialog__content {
  border: solid var(--fds-color--primary) 1px;
  border-radius: 0;
}

h3 {
  color: var(--fds-color--black);
}

.page-header {
  margin: 2rem;
}

.page-subject {
  margin: 2rem;
  color: var(--fds-color--black);
}

.contact-us-font {
  color: var(--fds-color--black);
}

.link-styling,
.link-styling:hover {
  color: $primary-color;
}
