.adminBar {
  max-width: 100%;
  width: 100%;
  padding: 2rem;

  p {
    color: var(--fds-color--black);
  }

  .adminBarTop {
    background-color: #d9d9d9;
    height: 5rem;

    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  td {
    background-color: var(--fds-color--gray1);
    padding: 1rem;
  }

  .adminExportBar {
    background-color: var(--fds-color--white);
    display: flex;
    justify-content: space-between;

    .select {
      width: 30%;
    }
  }
}

.adminPageHeader {
  margin: 2rem;
  font-family: var(--fmc-font--antenna);
}

.lincolnAdminPageHeader {
  margin: 2rem;
  font-family: var(--fmc-font--miller);
}

.adminDashboardContainer {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
}

.adminDownloadReports {
  grid-column: 1 / -1;
}

.adminRebilling {
  background-color: #f2f2f2;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 2rem solid #f2f2f2;

  div {
    margin-bottom: 0.5rem;
  }
}

.rebillingCompleted {
  margin-top: 1rem;
  strong {
    color: #008200;
  }
}

.rebillingModal {
  width: 25vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  button {
    margin-top: 3rem;
  }
}

.adminElementContainers {
  background: #ffffff;
  margin: 0.5rem;
  padding: 1rem;
}

.rebillingReportContainer {
  padding: 1rem;
  margin: 0.5rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
}

.adminsPermissionsContainer {
  padding: 1em;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.salesCodeContainer {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .salesCode {
    padding: 0 1em;
  }
}

.permissionSubHeader {
  text-align: left;
  font-weight: bold;
  padding-bottom: 0.25rem;
  padding-top: 0.75rem;
}

.permissionsHeader {
  text-align: left;
  margin-top: 2rem;
  margin-left: 2rem;
  color: var(--fds-color--black);
}

.rebillingHeader {
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  color: var(--fds-color--black);
}

.rebillingWrapper {
  display: grid;
  grid-gap: 1rem;
}

.reportHeader {
  margin-top: 2rem;
  color: var(--fds-color--black);
}

.adminButton {
  margin: 5px 5px 0 5px;
}

.adminText {
  padding: 2rem;
  color: var(--fds-color--black);
  text-align: left;
}

.onHoldVehicles {
  text-align: left;
  margin-left: 2.75rem;
  margin-top: 1rem;
}

.admin-tool-rebill-button {
  margin-top: 1rem;
  --shadow: none;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  &:hover,
  &:focus {
    --shadow: none !important;
  }
}
